* {
  box-sizing: border-box;
}

.annotation:hover {
  cursor: pointer;
}
.vertalign {
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
}

input.bold-on-checked:checked + label > span.preftext {
  font-weight: bold;
}
input.bold-on-checked {
  margin-right: 5px;
}

mark.negated {
  background-color: #f08080;
  color: black;
}

mark {
  background-color: #8fbc8f;
  color: black;
}

mark.historyOf {
  background-color: #d3d3d3;
  color: black;
}
